import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Form from './components/Form';
import NotFound from './components/NotFound';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/form/:id" element={<Form />} />
          <Route path="/not-found" element={<NotFound />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
